<template>
    <div id="compony-setting">
        <dl class="user-header">
            <dt class="avatar-wrap cursor">
                <Upload
                    class="upload"
                    action=""
                    :accept="formats"
                    :before-upload="handleBeforeUpload">
                    <img v-if="avatar_uri" class="image-center avatar" :src="`/cym/${avatar_uri}`">
                    <div>{{ $t('pages.setting.upload') }}</div>
                </Upload>
            </dt>
            <dd class="info-wrap">
                <div class="name-wrap pointer" @click="showElement($event, undefined, 22)">
                    <div class="name line-overflow">
                        {{ company_name }}
                    </div>
                    <Icon class="icon" type="md-create" size="18" />
                </div>
                <div class="user-id">{{ $t('pages.setting.companyID') }}:{{ company_rowid }}</div>
            </dd>
        </dl>
        <ul class="content-table">
            <li class="item">
                <p class="line-overflow">{{ $t('pages.setting.superAdmin') }}</p>
                <p class="line-overflow">{{ admin_user.name }}</p>
                <p class="line-overflow"></p>
            </li>
            <li class="item">
                <p class="line-overflow">{{ $t('pages.setting.companyIndustry') }}</p>
                <p class="line-overflow">
                    <span v-if="business.text">
                        {{ business.text[lang]?business.text[lang]:business.text._ }}
                    </span>
                </p>
                <p class="line-overflow cursor" @click="handleBiz">{{ $t('pages.setting.change') }}</p>
            </li>
        </ul>
        <business-modal
            :show="bizModalShow"
            :business="business"
            :lang="lang"
            @cancel="handleBizModalCancel"
            @confirm="handleBizModalSubmit"
        ></business-modal>
    </div>  
</template>
<script>
    import { rename } from '@/mixins/index'
    import businessModal from './components/businessModal'

    export default {
        name: "",
        mixins: [rename],
        components: {
            businessModal
        },
        props: [],
        data () {
            return {
                company_name: '',
                company_rowid: null,
                formats: '.jpg,.jpeg,.png',
                avatar_uri: '',
                business: {},
                admin_user: {},
                bizModalShow: false
            }
        },
        computed: {
            lang() {
                return this.$store.state.lang
            }
        },
        methods: {
            handleBeforeUpload(file) {
                let name = file.name,
                    reg = /^.*(\..*)$/,
                    format = name.match(reg)[1];

                //处理windows系统无法过滤类型
                if (this.formats.indexOf(format) === -1) {
                    this.$Message.error(this.$t('pages.space.unsupportedFormat'))
                    return false
                }
                this.handleUpload(file)
                return false
            },
            handleUpload(file) {
                let formData = new FormData()
                formData.append(file.name, file)
                this.$api.put('organizations/current/avatar', formData).then(() => {
                    this.$Message.success(this.$t('common.success'))
                    this.getData()
                })
            },
            handleRename(name) {
                this.$api.put('organizations/current/name', { name: name }).then(() => {
                    this.$Message.success(this.$t('message.success'))
                    this.$store.commit('SET_ORGANIZATION_NAME', name)
                })
            },
            getData() {
                this.$api.get('organizations/current').then(({ data }) => {
                    let { avatar_uri, name, rowid, business, admin_user } = data
                    this.avatar_uri = avatar_uri
                    this.company_rowid = rowid
                    this.company_name = name
                    this.business = business[0]
                    this.admin_user = admin_user[0]
                })
            },
            handleBiz() {
                this.bizModalShow = true
            },
            handleBizModalCancel() {
                this.bizModalShow = false
            },
            handleBizModalSubmit() {
                this.handleBizModalCancel()
                this.getData()
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>
<style scoped lang="less">
#compony-setting {
    padding-top: 80px;
    .user-header {
        padding-left: 22px;
        height: 60px;
        .avatar-wrap {
            position: relative;
            display: inline-block;
            margin-right: 15px;
            width: 60px;
            height: 60px;
            border-radius: 50% 50%;
            vertical-align: top;
            .upload {
                width: 100%;
                height: 100%;
                border-radius: 50% 50%;
                color: #fff;
                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60px;
                    height: 60px;
                }
                .avatar {
                    opacity: 1;
                    display: block;
                    width: 60px;
                    height: 60px;
                    border-radius: 50% 50%;
                    transition: opacity .3s ease;
                }
                &:hover {
                    .avatar {
                        opacity: 0;
                        transition: opacity .3s ease;
                    }
                }
            }
            &:hover {
                .upload {
                    background: #5a6875;
                }
            }
        }
        .info-wrap {
            display: inline-block;
            .name-wrap {
                display: inline-block;
                .name {
                    display: inline-block;
                    position: relative;
                    vertical-align: middle;
                    font-size: 22px;
                    line-height: 32px;
                }
                .icon {
                    vertical-align: middle;
                }
            }
        }
    }
    .content-table {
        margin-top: 47px;
        padding: 0 86px;
        background: #fff;
        .item {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-around;
            align-items: center;
            border-bottom: 1px solid #e6e9ee;
            height: 120px;
            font-size: 18px;
            &:last-child {
                border-bottom: none;
            }
            p {
                display: inline-block;
                width: 30%;
                text-align: center;
                &:first-child {
                    font-weight: 700;
                }
            }
        }
    }
}
.cursor {
    cursor: pointer;
}
</style>